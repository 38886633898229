import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getService, pageTitle } from "../../helper";
import Accordion from "../Accordion";
import Button from "../Button";
import Cta from "../Cta";
import IconBox from "../IconBox";
import PageHeading from "../PageHeading";
import Div from "../Div";
import SectionHeading from "../SectionHeading";
import TestimonialSlider from "../Slider/TestimonialSlider";
import Spacing from "../Spacing";
import services from "../../db/services";
import ErrorPage from "./ErrorPage";

export default function ServiceDetailsPage() {
  pageTitle("Service Details");
  const params = useParams();
  const service = getService(services, params.serviceDetailsId);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [service]);

  if (!service)
    return (
      <ErrorPage
        title="Service Not Found"
        subtitle="Sorry, we couldn't find the service you were looking for."
        btnText="Back To Services"
        btnLink="/service"
      />
    );

  return (
    <>
      <PageHeading
        title="Service Details"
        bgSrc="/images/service_hero_bg.jpeg"
        pageLinkText={service.id || params.serviceDetailsId}
      />
      <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title={service.serviceTitle || "Design working process"}
          subtitle={service.serviceSubtitle || "What Can We Do"}
          variant="cs-style1 text-center"
        />
        <Spacing lg="90" md="45" />
        <Div className="row">
          {service &&
            service.serviceContent &&
            service.serviceContent.map((item) => (
              <Div className="col-lg-4" key={item.id}>
                <IconBox
                  icon={item.icon || "/images/icons/service_icon_1.svg"}
                  title={item.title || "Service Title"}
                  subtitle={
                    item.description ||
                    "Service Content goes here. Service Content goes here. Service Content goes here."
                  }
                />
                <Spacing lg="30" md="30" />
              </Div>
            ))}
        </Div>
      </Div>
      <Spacing lg="120" md="50" />
      <Div className="container">
        <Div className="row align-items-center">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-radius_15 cs-shine_hover_1">
              <img
                src="/images/service_img_1.jpeg"
                alt="Service"
                className="cs-radius_15 w-100"
              />
            </Div>
            <Spacing lg="0" md="40" />
          </Div>
          <Div className="col-lg-6 offset-xl-1">
            <h2 className="cs-font_50 cs-m0">
              {service.relatedServicesTitle ||
                " Below our most design related services"}
            </h2>
            <Spacing lg="50" md="30" />
            <Div className="row">
              <Div className="col-lg-6">
                {service &&
                  service.relatedServices &&
                  service.relatedServices.slice(0, 6).map((item) => (
                    <>
                      <Button
                        key={item.id}
                        btnLink="/service/service-details"
                        btnText={item.title || "Related Service"}
                        variant="cs-type2"
                      />
                      <Spacing lg="20" md="10" />
                    </>
                  ))}
              </Div>
              <Div className="col-lg-6">
                {service &&
                  service.relatedServices &&
                  service.relatedServices
                    .slice(6, service.relatedServices.length)
                    .map((item) => (
                      <>
                        <Button
                          key={item.id}
                          btnLink="/service/service-details"
                          btnText={item.title || "Related Service"}
                          variant="cs-type2"
                        />
                        <Spacing lg="20" md="10" />
                      </>
                    ))}
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg="150" md="80" />
      <TestimonialSlider />
      <Spacing lg="145" md="80" />
      <Div className="container cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <SectionHeading
                title="Some pre questions and answers"
                subtitle="FAQ’s"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-lg-6 offset-xl-1">
              <Accordion />
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Cta
          title="Let’s disscuse make <br />something <i>cool</i> together"
          btnText="Apply For Meeting"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
    </>
  );
}
