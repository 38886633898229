import React, { useEffect } from "react";
import Cta from "../Cta";
import Div from "../Div";
import Hero5 from "../Hero/Hero5";
import Hero from "../Hero";
import LogoList from "../LogoList";
import MovingText from "../MovingText";
import Spacing from "../Spacing";
import VideoModal from "../VideoModal";
import { pageTitle } from "../../helper";
// import PostSlider from '../Slider/PostSlider';
import SectionHeading from "../SectionHeading";
import TestimonialSlider from "../Slider/TestimonialSlider";
// import PricingTableList from '../PricingTable/PricingTableList';
// import FunFact2 from '../FunFact/FunFact2';
import PortfolioSlider2 from "../Slider/PortfolioSlider2";
import ServiceList from "../ServiceList";
import MovingText2 from "../MovingText/MovingText2";
const heroSocialLinks = [
  {
    name: "LinkedIn",
    links: "https://www.linkedin.com/company/elevorixsolutions",
  },
  {
    name: "Instagram",
    links: "https://www.instagram.com/elevorixsolutions",
  },
];
// const funfaceData = [
//   {
//     title: 'Digital products',
//     factNumber: '550',
//   },
//   {
//     title: 'Global happy clients',
//     factNumber: '40K',
//   },
//   {
//     title: 'Project completed',
//     factNumber: '50k',
//   },
//   {
//     title: 'Team members',
//     factNumber: '250',
//   },
// ];
const serviceData1 = [
  {
    title: "Graphic Design",
    href: "/service/graphic-design",
  },
  {
    title: "Digital Marketing",
    href: "/service/digital-marketing",
  },
  {
    title: "Web Developement",
    href: "/service/web-development",
  },
];
const serviceData2 = [
  {
    title: "SEO",
    href: "/service/seo",
  },
  {
    title: "Video Editing",
    href: "/service/video-editing",
  },
  {
    title: "Social Media Marketing",
    href: "/service/social-media-marketing-ads-run",
  },
];

export default function Home() {
  const isFalse = false;
  pageTitle("Home");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* Start Hero Section */}
      {isFalse && <Hero />}
      <Hero5
        title="Grow Your Business<br /> With Digital Strategy"
        subtitle="We deliver best problem solving solution for our client and provide finest finishing product in present and upcoming future."
        btnLink="contact"
        btnText="Let’s talk"
        socialLinksHeading="Follow Us"
        heroSocialLinks={heroSocialLinks}
      />
      {/* End Hero Section */}

      {/* Start Video Block Section */}
      <Div className="cs-video_block_1_wrap">
        <Div className="container">
          <VideoModal
            videoSrc="https://www.youtube.com/watch?v=VcaAVWtP48A"
            bgUrl="/images/video_bg_2.jpeg"
          />
        </Div>
        <Spacing lg="65" md="45" />
      </Div>
      {/* End Video Block Section */}

      {/* Start Moving Text Section */}
      <MovingText2 data={serviceData1} />
      <Spacing lg="20" md="10" />
      <MovingText2 reverseDirection data={serviceData2} />
      {/* End Moving Text Section */}

      {/* Start Services Section */}
      <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="Our core services"
          subtitle="Services"
          variant="cs-style1 text-center"
        />
        <Spacing lg="70" md="45" />
        <ServiceList />
      </Div>
      {/* End Services Section */}

      {/* Start PortfolioSlider Section */}
      <Spacing lg="120" md="50" />
      <Div className="container">
        <h2 className="cs-font_50 cs-m0 cs-line_height_4">
          Our agile process is ability to adapt and respond to change. Agile
          organizations view change as an opportunity, not a threat.
        </h2>
      </Div>
      <Spacing lg="90" md="70" />
      <PortfolioSlider2 />
      {/* End PortfolioSlider Section */}

      {/* Start FunFact Section */}
      {/* <Spacing lg="150" md="80" />
      <Div className="container">
        <FunFact2
          data={funfaceData}
          variant="cs-no_shadow"
          bgUrl="/images/funfact_shape_bg.svg"
        />
      </Div> */}
      {/* End FunFact Section */}

      {/* Start Pricing Section */}
      {/* <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="Providing best <br/>pricing for client"
          subtitle="Pricing & Packaging"
        />
        <Spacing lg="85" md="40" />
        <PricingTableList />
      </Div>
      <Spacing lg="125" md="55" /> */}
      {/* End Pricing Section */}

      {/* Start Testimonial Section */}
      <TestimonialSlider />
      {/* End Testimonial Section */}

      {/* Start Blog Section */}
      {/* <Spacing lg="150" md="80" />
      <Div className="cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="Explore recent publication"
                subtitle="Our Blog"
                btnText="View More Blog"
                btnLink="/blog"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-7 offset-xl-1">
              <Div className="cs-half_of_full_width">
                <PostSlider />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div> */}
      {/* End Blog Section */}

      {/* Start MovingText Section */}
      <Spacing lg="125" md="70" />
      <MovingText text="Our reputed world wide partners" />
      <Spacing lg="100" md="70" />
      {/* End MovingText Section */}

      {/* Start LogoList Section */}
      <Div className="container">
        <LogoList />
      </Div>
      <Spacing lg="130" md="80" />
      {/* End LogoList Section */}

      {/* Start CTA Section */}
      <Div className="container">
        <Cta
          title="Let’s disscuse make <br />something <i>cool</i> together"
          btnText="Apply For Meeting"
          btnLink="/contact"
          bgSrc="/images/cta_bg_3.jpeg"
        />
      </Div>
      {/* End CTA Section */}
    </>
  );
}
