const services = [
  {
    id: "graphic-design",
    serviceTitle: "Graphic Design Services",
    serviceSubtitle: "Transforming Ideas into Visual Masterpieces",
    serviceDescription:
      "Our graphic design services encompass a wide range of creative solutions tailored to elevate your brand identity and communicate your message effectively. From logos to marketing materials, we bring your vision to life.",
    serviceContent: [
      {
        id: "service-content-1",
        title: "Logo Design",
        description:
          "Craft unique and memorable logos that capture your brand's essence and set you apart from the competition.",
        icon: "/images/icons/service_icon_1.svg",
      },
      {
        id: "service-content-2",
        title: "Print Design",
        description:
          "Create stunning print materials including brochures, flyers, and posters that engage your audience and drive results.",
        icon: "/images/icons/service_icon_2.svg",
      },
      {
        id: "service-content-3",
        title: "Digital Design",
        description:
          "Design eye-catching graphics for your website, social media, and online advertising to boost your digital presence.",
        icon: "/images/icons/service_icon_3.svg",
      },
    ],
    relatedServicesTitle: "Below are our most popular related services:",
    relatedServices: [
      {
        id: "web-design",
        title: "Web Design",
      },
      {
        id: "branding",
        title: "Branding Strategy",
      },
      {
        id: "illustration",
        title: "Custom Illustration",
      },
      {
        id: "packaging-design",
        title: "Packaging Design",
      },
      {
        id: "advertising-design",
        title: "Advertising Design",
      },
      {
        id: "infographics",
        title: "Infographics Design",
      },
      {
        id: "social-media-design",
        title: "Social Media Graphics",
      },
      {
        id: "ui-ux-design",
        title: "UI/UX Design",
      },
      {
        id: "presentation-design",
        title: "Presentation Design",
      },
      {
        id: "other-design",
        title: "Other Design",
      },
    ],
  },
  {
    id: "digital-marketing",
    serviceTitle: "Digital Marketing Services",
    serviceSubtitle: "Maximize Your Online Presence",
    serviceDescription:
      "Our digital marketing services are designed to enhance your online visibility, engage your audience, and drive conversions. From SEO to social media marketing, we develop tailored strategies that align with your business goals.",
    serviceContent: [
      {
        id: "service-content-1",
        title: "Search Engine Optimization (SEO)",
        description:
          "Optimize your website to rank higher in search engine results, driving organic traffic and increasing visibility.",
        icon: "/images/icons/service_icon_1.svg",
      },
      {
        id: "service-content-2",
        title: "Social Media Marketing",
        description:
          "Leverage social media platforms to connect with your audience, promote your brand, and drive engagement.",
        icon: "/images/icons/service_icon_2.svg",
      },
      {
        id: "service-content-3",
        title: "Content Marketing",
        description:
          "Create valuable content that attracts, informs, and engages your target audience, building brand loyalty.",
        icon: "/images/icons/service_icon_3.svg",
      },
    ],
    relatedServicesTitle: "Below are our most popular related services:",
    relatedServices: [
      {
        id: "email-marketing",
        title: "Email Marketing",
      },
      {
        id: "pay-per-click",
        title: "Pay-Per-Click Advertising",
      },
      {
        id: "conversion-optimization",
        title: "Conversion Rate Optimization",
      },
      {
        id: "analytics-reporting",
        title: "Analytics & Reporting",
      },
      {
        id: "affiliate-marketing",
        title: "Affiliate Marketing",
      },
      {
        id: "video-marketing",
        title: "Video Marketing",
      },
      {
        id: "influencer-marketing",
        title: "Influencer Marketing",
      },
      {
        id: "mobile-marketing",
        title: "Mobile Marketing",
      },
      {
        id: "web-development",
        title: "Web Development",
      },
      {
        id: "other-marketing",
        title: "Other Marketing",
      },
    ],
  },
  {
    id: "seo",
    serviceTitle: "SEO Services",
    serviceSubtitle: "Boost Your Online Visibility",
    serviceDescription:
      "Our SEO services are designed to enhance your website's visibility in search engines, attract organic traffic, and improve your online presence. We utilize proven strategies and techniques to help you climb the search rankings and reach your target audience effectively.",
    serviceContent: [
      {
        id: "service-content-1",
        title: "Keyword Research",
        description:
          "Identify the best keywords for your business to target, ensuring that your content aligns with what your audience is searching for.",
        icon: "/images/icons/service_icon_1.svg",
      },
      {
        id: "service-content-2",
        title: "On-Page Optimization",
        description:
          "Optimize your website's content, meta tags, and structure to improve its relevance and visibility in search results.",
        icon: "/images/icons/service_icon_2.svg",
      },
      {
        id: "service-content-3",
        title: "Link Building",
        description:
          "Develop a robust backlink strategy to enhance your site's authority and improve its rankings on search engines.",
        icon: "/images/icons/service_icon_3.svg",
      },
    ],
    relatedServicesTitle: "Below are our most popular related services:",
    relatedServices: [
      {
        id: "content-marketing",
        title: "Content Marketing",
      },
      {
        id: "local-seo",
        title: "Local SEO",
      },
      {
        id: "technical-seo",
        title: "Technical SEO",
      },
      {
        id: "seo-audit",
        title: "SEO Audit",
      },
      {
        id: "mobile-seo",
        title: "Mobile SEO",
      },
      {
        id: "video-seo",
        title: "Video SEO",
      },
      {
        id: "analytics-and-reporting",
        title: "Analytics & Reporting",
      },
      {
        id: "social-media-seo",
        title: "Social Media SEO",
      },
      {
        id: "ecommerce-seo",
        title: "Ecommerce SEO",
      },
      {
        id: "other-seo",
        title: "Other SEO",
      },
    ],
  },
  {
    id: "video-editing",
    serviceTitle: "Video Editing Services",
    serviceSubtitle: "Transform Your Footage into Compelling Stories",
    serviceDescription:
      "Our video editing services provide professional editing to create engaging videos that captivate your audience. Whether it’s for marketing, social media, or personal projects, we enhance your footage to deliver a polished final product.",
    serviceContent: [
      {
        id: "service-content-1",
        title: "Basic Editing",
        description:
          "Cut, trim, and arrange your footage to create a coherent and engaging narrative.",
        icon: "/images/icons/service_icon_1.svg",
      },
      {
        id: "service-content-2",
        title: "Color Correction",
        description:
          "Enhance the visual appeal of your videos with professional color grading and correction techniques.",
        icon: "/images/icons/service_icon_2.svg",
      },
      {
        id: "service-content-3",
        title: "Motion Graphics",
        description:
          "Incorporate dynamic text and graphics to elevate your videos and deliver information creatively.",
        icon: "/images/icons/service_icon_3.svg",
      },
    ],
    relatedServicesTitle: "Below are our most popular related services:",
    relatedServices: [
      {
        id: "video-production",
        title: "Video Production",
      },
      {
        id: "animation",
        title: "Animation Services",
      },
      {
        id: "audio-editing",
        title: "Audio Editing",
      },
      {
        id: "script-writing",
        title: "Script Writing",
      },
      {
        id: "social-media-video",
        title: "Social Media Video Creation",
      },
      {
        id: "corporate-video",
        title: "Corporate Video Production",
      },
      {
        id: "event-video",
        title: "Event Video Coverage",
      },
      {
        id: "tutorial-video",
        title: "Tutorial Video Production",
      },
      {
        id: "live-streaming",
        title: "Live Streaming Services",
      },
      {
        id: "other-video",
        title: "Other Video Editing",
      },
    ],
  },
  {
    id: "social-media-management",
    serviceTitle: "Social Media Management Services",
    serviceSubtitle: "Elevate Your Brand's Online Presence",
    serviceDescription:
      "Our social media management services help you engage your audience, build brand loyalty, and drive traffic to your website. We create and implement tailored strategies that resonate with your target market across various platforms.",
    serviceContent: [
      {
        id: "service-content-1",
        title: "Content Creation",
        description:
          "Develop high-quality, engaging content that aligns with your brand voice and resonates with your audience.",
        icon: "/images/icons/service_icon_1.svg",
      },
      {
        id: "service-content-2",
        title: "Social Media Strategy",
        description:
          "Craft a comprehensive strategy to maximize your social media presence and achieve your marketing goals.",
        icon: "/images/icons/service_icon_2.svg",
      },
      {
        id: "service-content-3",
        title: "Analytics and Reporting",
        description:
          "Monitor and analyze performance metrics to continually optimize your social media efforts and demonstrate ROI.",
        icon: "/images/icons/service_icon_3.svg",
      },
    ],
    relatedServicesTitle: "Below are our most popular related services:",
    relatedServices: [
      {
        id: "content-marketing",
        title: "Content Marketing",
      },
      {
        id: "email-marketing",
        title: "Email Marketing",
      },
      {
        id: "paid-advertising",
        title: "Paid Advertising Management",
      },
      {
        id: "influencer-marketing",
        title: "Influencer Marketing",
      },
      {
        id: "community-management",
        title: "Community Management",
      },
      {
        id: "social-media-audit",
        title: "Social Media Audit",
      },
      {
        id: "brand-monitoring",
        title: "Brand Monitoring",
      },
      {
        id: "crisis-management",
        title: "Crisis Management",
      },
      {
        id: "graphic-design",
        title: "Graphic Design Services",
      },
      {
        id: "other-social-media",
        title: "Other Social Media Management",
      },
    ],
  },
  {
    id: "social-media-marketing-ads-run",
    serviceTitle: "Social Media Marketing Ads Management",
    serviceSubtitle: "Maximize Your Ad Spend and Reach Your Audience",
    serviceContent: [
      {
        id: "service-content-1",
        title: "Targeted Ad Campaigns",
        description:
          "Create and manage highly targeted ad campaigns to ensure your message reaches the right audience on platforms like Facebook, Instagram, and LinkedIn.",
        icon: "/images/icons/service_icon_1.svg",
      },
      {
        id: "service-content-2",
        title: "Creative Ad Design",
        description:
          "Develop eye-catching ad creatives that capture attention and drive engagement, tailored to your brand and campaign objectives.",
        icon: "/images/icons/service_icon_2.svg",
      },
      {
        id: "service-content-3",
        title: "Performance Analytics",
        description:
          "Monitor and analyze ad performance metrics to optimize campaigns for better results and return on investment (ROI).",
        icon: "/images/icons/service_icon_3.svg",
      },
    ],
    relatedServicesTitle: "Below are our most popular related services:",
    relatedServices: [
      {
        id: "social-media-management",
        title: "Social Media Management",
      },
      {
        id: "content-marketing",
        title: "Content Marketing",
      },
      {
        id: "search-engine-marketing",
        title: "Search Engine Marketing",
      },
      {
        id: "email-marketing",
        title: "Email Marketing",
      },
      {
        id: "graphic-design",
        title: "Graphic Design Services",
      },
      {
        id: "video-marketing",
        title: "Video Marketing",
      },
      {
        id: "influencer-marketing",
        title: "Influencer Marketing",
      },
      {
        id: "analytics-and-reporting",
        title: "Analytics & Reporting",
      },
      {
        id: "retargeting-ads",
        title: "Retargeting Ads",
      },
      {
        id: "other-social-media",
        title: "Other Social Media Management",
      },
    ],
  },
  {
    id: "service-details",
    serviceTitle: "Comprehensive Service Solutions",
    serviceSubtitle: "Tailored Strategies for Your Business Needs",
    serviceDescription:
      "Our service offerings are designed to meet the diverse needs of businesses in today’s digital landscape. From social media management to SEO, we provide customized strategies that drive results and help you achieve your business goals.",
    serviceContent: [
      {
        id: "service-content-1",
        title: "Expert Consultation",
        description:
          "Receive tailored consultations that identify your unique challenges and opportunities, setting the stage for effective strategies.",
        icon: "/images/icons/service_icon_1.svg",
      },
      {
        id: "service-content-2",
        title: "Implementation Services",
        description:
          "Our team handles the execution of strategies, ensuring that each step is managed professionally for maximum impact.",
        icon: "/images/icons/service_icon_2.svg",
      },
      {
        id: "service-content-3",
        title: "Ongoing Support",
        description:
          "We offer continuous support and optimization services to adapt your strategies to changing market conditions and business needs.",
        icon: "/images/icons/service_icon_3.svg",
      },
    ],
    relatedServicesTitle: "Below are our most popular related services:",
    relatedServices: [
      {
        id: "social-media-management",
        title: "Social Media Management",
      },
      {
        id: "seo",
        title: "SEO Services",
      },
      {
        id: "content-marketing",
        title: "Content Marketing",
      },
      {
        id: "email-marketing",
        title: "Email Marketing",
      },
      {
        id: "video-editing",
        title: "Video Editing Services",
      },
      {
        id: "graphic-design",
        title: "Graphic Design Services",
      },
      {
        id: "pay-per-click",
        title: "Pay-Per-Click Advertising",
      },
      {
        id: "web-development",
        title: "Web Development",
      },
      {
        id: "influencer-marketing",
        title: "Influencer Marketing",
      },
      {
        id: "Other",
        title: "Other Services",
      },
    ],
  },
  {
    id: "web-development",
    serviceTitle: "Web Development Services",
    serviceSubtitle: "Crafting High-Performance Websites",
    serviceDescription:
      "Our web development services focus on creating responsive, user-friendly websites tailored to your business needs. From e-commerce platforms to portfolio sites, we ensure a seamless online experience that drives engagement and conversions.",
    serviceContent: [
      {
        id: "service-content-1",
        title: "Custom Website Development",
        description:
          "Build a unique website from the ground up, designed to reflect your brand and engage your audience effectively.",
        icon: "/images/icons/service_icon_1.svg",
      },
      {
        id: "service-content-2",
        title: "E-commerce Solutions",
        description:
          "Develop fully functional e-commerce platforms that facilitate online sales and provide a smooth shopping experience for your customers.",
        icon: "/images/icons/service_icon_2.svg",
      },
      {
        id: "service-content-3",
        title: "Content Management Systems (CMS)",
        description:
          "Implement user-friendly CMS solutions that allow you to manage your website content effortlessly.",
        icon: "/images/icons/service_icon_3.svg",
      },
    ],
    relatedServicesTitle: "Below are our most popular related services:",
    relatedServices: [
      {
        id: "seo",
        title: "SEO Services",
      },
      {
        id: "graphic-design",
        title: "Graphic Design Services",
      },
      {
        id: "content-marketing",
        title: "Content Marketing",
      },
      {
        id: "social-media-management",
        title: "Social Media Management",
      },
      {
        id: "mobile-app-development",
        title: "Mobile App Development",
      },
      {
        id: "web-design",
        title: "Web Design Services",
      },
      {
        id: "digital-marketing",
        title: "Digital Marketing",
      },
      {
        id: "ecommerce-marketing",
        title: "E-commerce Marketing",
      },
      {
        id: "ui-ux-design",
        title: "UI/UX Design",
      },
      {
        id: "other-web-development",
        title: "Other Web Development Services",
      },
    ],
  },
];

export default services;
